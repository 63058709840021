import React from "react";

interface ComponentSvgProps {
  className?: string; // So we can do <ComponentSvg className="text-text" />
}

export default function ComponentSvg({ className }: ComponentSvgProps) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="80px"
      height="33px"
      viewBox="0 0 213.936 73.834"
      enableBackground="new 0 0 213.936 73.834"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#BF2C2A"
          d="M52.712,51l15.052-15.944c3.114-3.3,4.68-7.652,4.68-11.996c0-4.343-1.557-8.696-4.68-11.995
		C64.65,7.767,60.541,6.108,56.44,6.108c-4.099,0-8.208,1.648-11.322,4.948l-4.463,4.728l-4.404-4.664
		c-3.114-3.299-7.214-4.949-11.323-4.949V6.163c-4.099,0-8.209,1.649-11.323,4.958c-3.114,3.299-4.68,7.651-4.68,11.995
		c0,4.343,1.557,8.696,4.672,11.995l29.566,31.322l-2.517,2.666L11.079,37.777c-3.797-4.032-5.701-9.338-5.701-14.662
		c0-5.315,1.904-10.63,5.701-14.661c3.798-4.033,8.814-6.039,13.84-6.039v-0.01c5.018,0,10.035,2.016,13.841,6.049l1.886,1.997
		l1.945-2.062c3.807-4.032,8.815-6.039,13.841-6.039c5.017,0,10.035,2.016,13.832,6.039c3.797,4.023,5.7,9.338,5.7,14.661
		c0,5.316-1.903,10.63-5.7,14.662L55.212,53.659l-2.516-2.668L52.712,51z M48.586,44.722l14.153-14.991
		c1.729-1.833,2.594-4.253,2.594-6.671c0-2.419-0.865-4.839-2.594-6.671c-1.722-1.824-4.014-2.74-6.299-2.74V13.64
		c-2.283,0-4.566,0.916-6.297,2.749l-9.488,10.053l-9.43-9.989c-1.729-1.832-4.014-2.749-6.297-2.749v0.008
		c-2.284,0-4.576,0.918-6.297,2.741c-1.722,1.824-2.587,4.252-2.587,6.67h-0.009c0,2.42,0.865,4.839,2.596,6.672l29.566,31.321
		l-2.517,2.666L16.113,32.461c-2.413-2.556-3.624-5.946-3.624-9.338H12.48c0-3.38,1.212-6.771,3.633-9.336
		c2.423-2.566,5.614-3.85,8.815-3.85v0.01c3.201,0,6.393,1.283,8.806,3.84l6.912,7.32l6.972-7.385
		c2.413-2.557,5.613-3.839,8.806-3.839v-0.01c3.191,0,6.392,1.283,8.814,3.849c2.414,2.557,3.625,5.947,3.625,9.338
		c0,3.39-1.211,6.772-3.625,9.338L51.087,47.389l-2.518-2.667H48.586z M43.517,39.435l14.187-15.028
		c0.337-0.366,0.51-0.852,0.51-1.347c0-0.495-0.173-0.98-0.51-1.347c-0.347-0.357-0.805-0.541-1.272-0.541
		c-0.467,0-0.925,0.184-1.271,0.541L40.646,37.089L26.191,21.777c-0.337-0.357-0.805-0.54-1.272-0.54
		c-0.467,0-0.934,0.182-1.272,0.54h-0.008c-0.337,0.357-0.502,0.844-0.502,1.347c0,0.504,0.165,0.99,0.502,1.348l29.558,31.312
		l-2.517,2.667L21.122,27.138v-0.01c-1.029-1.09-1.548-2.547-1.548-4.005c0-1.447,0.519-2.903,1.548-4.003
		c1.029-1.091,2.413-1.641,3.78-1.641c1.375,0,2.752,0.55,3.789,1.641l11.937,12.646l11.997-12.711
		c1.03-1.09,2.406-1.631,3.781-1.631s2.751,0.541,3.78,1.631h0.009c1.03,1.09,1.54,2.547,1.54,4.014c0,1.457-0.51,2.914-1.54,4.004
		v0.009L46.009,42.112l-2.517-2.667L43.517,39.435z"
        />
        <g>
          <path
            fill="currentColor"
            d="M81.945,10.116h13.792v2.774H85.25V21.4h9.689v2.735H85.25v11.588h-3.305V10.116z"
          />
          <path
            fill="currentColor"
            d="M100.451,10.116h3.305v22.835h10.942v2.773h-14.248V10.116z"
          />
          <path
            fill="currentColor"
            d="M121.691,10.116v25.608h-3.306V10.116H121.691z"
          />
          <path
            fill="currentColor"
            d="M127.468,10.458c1.672-0.342,4.065-0.532,6.345-0.532c3.533,0,5.813,0.646,7.409,2.09
			c1.292,1.14,2.014,2.888,2.014,4.863c0,3.381-2.128,5.623-4.825,6.535v0.114c1.976,0.684,3.153,2.508,3.762,5.167
			c0.836,3.571,1.443,6.041,1.976,7.029h-3.42c-0.418-0.722-0.987-2.926-1.71-6.117c-0.76-3.533-2.127-4.863-5.129-4.977h-3.115
			v11.094h-3.306V10.458z M130.773,22.122h3.381c3.534,0,5.775-1.938,5.775-4.863c0-3.306-2.394-4.749-5.889-4.787
			c-1.596,0-2.736,0.152-3.268,0.304V22.122z"
          />
          <path
            fill="currentColor"
            d="M152.775,12.927h-7.789v-2.812h18.959v2.812h-7.826v22.797h-3.344V12.927z"
          />
          <path
            fill="currentColor"
            d="M172.497,35.724V24.858l-8.093-14.742h3.762l3.609,7.067c0.987,1.938,1.747,3.495,2.545,5.281h0.076
			c0.722-1.671,1.596-3.343,2.584-5.281l3.686-7.067h3.761l-8.586,14.704v10.904H172.497z"
          />
        </g>
        <g>
          <path
            fill="#BF2C2A"
            d="M81.945,40.44c1.444-0.304,3.723-0.531,6.041-0.531c3.306,0,5.433,0.569,7.029,1.861
			c1.33,0.988,2.128,2.508,2.128,4.521c0,2.47-1.634,4.635-4.332,5.623v0.076c2.432,0.607,5.281,2.621,5.281,6.421
			c0,2.204-0.874,3.875-2.166,5.129c-1.786,1.634-4.673,2.394-8.853,2.394c-2.279,0-4.027-0.151-5.129-0.304V40.44z M85.25,50.926
			h3.002c3.495,0,5.547-1.823,5.547-4.293c0-3.002-2.279-4.18-5.623-4.18c-1.52,0-2.394,0.114-2.926,0.229V50.926z M85.25,63.199
			c0.646,0.114,1.596,0.152,2.774,0.152c3.419,0,6.573-1.254,6.573-4.978c0-3.495-3.001-4.939-6.611-4.939H85.25V63.199z"
          />
          <path
            fill="#BF2C2A"
            d="M105.846,40.098v15.16c0,5.737,2.546,8.169,5.965,8.169c3.8,0,6.231-2.508,6.231-8.169v-15.16h3.344V55.03
			c0,7.865-4.142,11.095-9.688,11.095c-5.243,0-9.195-3.002-9.195-10.942V40.098H105.846z"
          />
          <path
            fill="#BF2C2A"
            d="M148.707,54.46c-0.19-3.571-0.418-7.865-0.38-11.057h-0.114c-0.874,3.002-1.938,6.193-3.229,9.727
			l-4.521,12.424h-2.508l-4.142-12.196c-1.216-3.609-2.241-6.914-2.963-9.954h-0.076c-0.076,3.191-0.266,7.485-0.494,11.322
			l-0.684,10.98h-3.153l1.785-25.608h4.218l4.369,12.387c1.063,3.153,1.938,5.965,2.584,8.624h0.113
			c0.646-2.583,1.558-5.395,2.698-8.624l4.559-12.387h4.218l1.596,25.608h-3.229L148.707,54.46z"
          />
        </g>
        <rect y="0" fill="none" width="213.936" height="65.834" />
      </g>
    </svg>
  );
}
